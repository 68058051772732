import SignInService from "../services/Auth";
import { getData, storeObjectData } from "../services/Storage";
//import decode from 'jwt-decode';
export default async function resetToken(params) {
  let tokenData = JSON.parse(await getData("userTokens"));
  let refreshTokenData = {
    refreshToken: tokenData.refreshToken,
  };
  return SignInService.refreshToken(JSON.stringify(refreshTokenData))
    .then(async (response) => {
      if (response.data.data) {
        await storeObjectData("userTokens", response.data.data);
        return true;
      }
    })
    .catch((er) => {
      return false;
    });
}
