import React, { useState } from "react";

const UserContext = React.createContext();

function UserProvider(props) {
  const [contextUserRoles, setContextUserRoles] = useState([]);
  return (
    <UserContext.Provider value={{ contextUserRoles, setContextUserRoles }}>
      {props.children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
