import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import "./scss/style.scss";
import "react-toastify/dist/ReactToastify.css";
import { storeObjectData, getObjectData, getData } from "./services/Storage";
import { UserContext } from "./contexts/user";
import { io } from "socket.io-client";
// import { SocketContext } from "./contexts/socket";
import SignInService from "./services/Auth";
import decode from "jwt-decode";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Login = React.lazy(() => import("./views/pages/login/Login"));
const Otp = React.lazy(() => import("./views/pages/otp/Otp"));
// const Register = React.lazy(() => import("./views/pages/register/Register"));
// const Page404 = React.lazy(() => import("./views/pages/page404/Page404"));
// const Page500 = React.lazy(() => import("./views/pages/page500/Page500"));

// const socket = io("ws://stec-locations.sofyrus.com",{
//   withCredentials: true,
//   forceNew: true,
//   reconnectionAttempts: "Infinity", //avoid having user reconnect manually in order to prevent dead clients after a server restart
//   timeout: 10000, //before connect_error and connect_timeout are emitted.
//   transports: ['websocket']
// });

// Socket.on("connect", async () => {
//   console.log(Socket.id); // x8WIv7-mJelg7on_ALbx
//   let data = JSON.parse(await getData("userDetail"));
//    if (data != null && data.role.includes("TENANT")) {
//     Socket.emit("details", {
//       role: "admin",
//       admin_id: data.tenant_id,
//       conn_id: Socket.id,
//     });
//   }
// });

class App extends Component {
  state = {
    isAuthenticated: false,
    Roles: [],
  };
  static contextType = UserContext;

  Refreshtoken = async () => {
    let payload = await getObjectData("users");
    if (payload) {
      const buff = Buffer.from(payload.password, "base64");

      const str = buff.toString("utf8");
      payload.password = str;

      let response = await SignInService.LogIn(payload);
      if (response && response.data) {
        let { success } = response.data;
        if (success) {
          let userTokens = response.data.data;

          await storeObjectData("userTokens", userTokens);
        }
      }
    }
  };

  componentDidMount = async () => {
    const { setContextUserRoles } = this.context;
    let userDetail = await getObjectData("userDetail");
    let loggedIn = userDetail ? true : false;
    if (loggedIn) setContextUserRoles(userDetail.role);
    this.setState({ isAuthenticated: loggedIn });
    console.log("Hello");
    setInterval(() => this.Refreshtoken(), 300000);
  };
  render() {
    const { isAuthenticated } = this.state;
    return (
      // <SocketContext.Provider value={Socket}>
        <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route
                exact
                path="/"
                name="Login Page"
                render={(props) => <Login {...props} />}
              />
              <Route
                exact
                path="/otp"
                name="Otp Page"
                render={(props) => <Otp {...props} />}
              />
              {isAuthenticated && (
                <Route
                  path="/"
                  name="Dashboard"
                  render={(props) => (
                    <TheLayout {...props} Roles={this.state.Roles} />
                  )}
                />
              )}
              {/* <Route
              exact
              path="/register"
              name="Register Page"
              render={(props) => <Register {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            /> */}
              {/* <Route
              path="/"
              name="Dashboard"
              render={(props) => <TheLayout {...props} />}
            /> */}
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      // </SocketContext.Provider>
    );
  }
}
export default App;
