import axios from "axios";
import Config from "./../config";
import { getData, logOut } from "../services/Storage";
import resetToken from "./tokenChecker";

export const postResponse = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios(URL, {
    method: "POST",
    headers: header,
    data: payload,
  })
    .then((response) => response)
    .catch(async (error) => {
      
      if (error.response.status === 401) {
        if (await resetToken()) return await postResponse(url, payload);
      }
      return error;
    });
};

export const getResponse = async (url, params) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios(URL, {
    method: "GET",
    headers: header,
  })
    .then((response) => response)
    .catch(async (error) => {
      
      if (error.response.status === 401) {
        if (await resetToken()) return await getResponse(url, params);
      }
      return error;
    });
};

export const updateCall = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader();
  return axios(URL, {
    method: "PUT",
    headers: header,
    data: payload,
  })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status === 401) {
        if (await resetToken()) return await updateCall(url, payload);
      }
      return error.response;
    });
};
export const deleteResponse = async (url, payload) => {
  const URL = `${Config.BaseUrl}${url}/${payload}`;
  const header = await getHeader();
  return axios(URL, {
    method: "DELETE",
    headers: header,
  })
    .then((response) => response)
    .catch(async (error) => {
      if (error.response.status === 401) {
        if (await resetToken()) return await deleteResponse(url, payload);
      }
      return error;
    });
};

export const postResponseFormData = async (url, payload) => {
  const URL = Config.BaseUrl + url;
  const header = await getHeader(true);
  return axios
    .post(URL, payload, {
      headers: header,
    })
    .then((response) => response)
    .catch((error) => {
      return error;
    });
};

const getHeader = async (formData) => {
  let token = null;
  let tokenData = JSON.parse(await getData("userTokens"));
  if (tokenData) token = `jwt ${tokenData.token}`;
  return {
    Accept: formData ? "application/json" : "application/json",
    "Content-Type": formData ? "multipart/form-data;" : "application/json",
    Authorization: token,
  };
};
