import React, { useState } from "react";

const EmployeeContext = React.createContext();

function EmployeeProvider(props) {
  const [contextEmployee, setContextEmployees] = useState(null);
  return (
    <EmployeeContext.Provider value={{ contextEmployee, setContextEmployees }}>
      {props.children}
    </EmployeeContext.Provider>
  );
}

export { EmployeeContext, EmployeeProvider };
