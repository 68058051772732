import * as AuthenticateAPI from "../actions/ApiCalls";

const signIn = async (payload) => {
  return await AuthenticateAPI.getResponse(
    `/verify/${payload.phoneNumber}`,
    payload
  );
};

const LogIn = async (payload) => {
  return await AuthenticateAPI.postResponse(`/users/validation`, payload);
};

const validateOtp = async (payload) => {
  return await AuthenticateAPI.postResponse(`/verify`, payload);
};

const refreshToken = async (payload) => {
  return await AuthenticateAPI.updateCall(`/token/refresh`, payload);
};

const addOrUpdateDeviceToken = async (payload) => {
  return await AuthenticateAPI.postResponse(`/deviceTokens`, payload);
};

const SignServices = {
  signIn,
  LogIn,
  validateOtp,
  refreshToken,
  addOrUpdateDeviceToken,
};

export default SignServices;
